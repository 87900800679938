<template>
    <svg v-if="state" width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M2.3372 5.00008C1.60107 5.00008 1 5.60115 1 6.33728V18.1046C1 18.8407 1.60107 19.4418 2.3372 19.4418H17.3138C18.0499 19.4418 18.651 18.8407 18.651 18.1046V15.656V8.78588V6.33723C18.651 5.6011 18.0499 5.00003 17.3138 5.00003H3.94184L2.3372 5.00008ZM17.3138 5.53488H3.94184L2.3372 5.53493C1.88816 5.53493 1.53483 5.88808 1.53483 6.3373V18.1046C1.53483 18.5539 1.88817 18.907 2.3372 18.907H17.3138C17.7629 18.907 18.1162 18.5538 18.1162 18.1046V15.1629V9.27898V6.33724C18.1162 5.88802 17.7628 5.53488 17.3138 5.53488Z"
              fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M23.5905 18.8652C23.6728 18.9167 23.7765 18.9197 23.8616 18.8728C23.9466 18.826 23.9996 18.7367 24 18.6395V5.80238C23.9996 5.70518 23.9466 5.61583 23.8616 5.569C23.7765 5.52218 23.6728 5.52516 23.5905 5.57665L19.9077 7.96941C19.6238 8.15386 19.4525 8.46942 19.4525 8.80797V8.90254V15.5393V15.6339C19.4525 15.9724 19.6238 16.288 19.9077 16.4724L23.5905 18.8652ZM23.4652 18.1464V6.29548L20.4552 8.25109C20.1713 8.43553 20 8.75109 20 9.08964V15.3522C20 15.6908 20.1713 16.0063 20.4552 16.1908L23.4652 18.1464Z"
              fill="white"/>
        <path
                d="M3.94184 5.53488H17.3138C17.7628 5.53488 18.1162 5.88802 18.1162 6.33724V9.27898V15.1629V18.1046C18.1162 18.5538 17.7629 18.907 17.3138 18.907H2.3372C1.88817 18.907 1.53483 18.5539 1.53483 18.1046V6.3373C1.53483 5.88808 1.88816 5.53493 2.3372 5.53493L3.94184 5.53488Z"
                fill="white"/>
        <path
                d="M23.4652 6.29548V18.1464L20.4552 16.1908C20.1713 16.0063 20 15.6908 20 15.3522V9.08964C20 8.75109 20.1713 8.43553 20.4552 8.25109L23.4652 6.29548Z"
                fill="white"/>
    </svg>
    <svg v-if="!state" width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
                d="M1.14645 0.853553L4.79293 4.50003H4.20714L0.853554 1.14645C0.772673 1.06557 0.772673 0.934434 0.853553 0.853553C0.934434 0.772673 1.06557 0.772673 1.14645 0.853553ZM4.32777 6.03488L16.6999 18.407H2.3372C2.16423 18.407 2.03483 18.2776 2.03483 18.1046V6.3373C2.03483 6.1643 2.16423 6.03493 2.3372 6.03493H2.33721L3.94184 6.03488H3.94185H4.32777ZM18.8963 19.1892L18.8371 19.1299C18.9178 19.0104 18.985 18.8807 19.0362 18.7433L19.151 18.8581L23.1464 22.8536C23.2273 22.9344 23.2273 23.0656 23.1464 23.1464C23.0656 23.2273 22.9344 23.2273 22.8536 23.1464L18.8963 19.1892ZM17.6162 15.9091L7.74198 6.03488H17.3138C17.4868 6.03488 17.6162 6.16424 17.6162 6.33724V9.27898V15.1629V15.9091ZM20.7276 8.67036L22.9652 7.2166V17.2253L20.7276 15.7715C20.5856 15.6793 20.5 15.5215 20.5 15.3522V9.08964C20.5 8.92037 20.5856 8.76259 20.7276 8.67036Z"
                fill="white" stroke="white"/>
    </svg>
</template>

<script>
export default {
    props: {
        state: Boolean
    },
    name: "CameraIcons"
}
</script>

<style>

</style>
