<template>
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M17.0983 5.66452C17.0983 5.07127 16.7825 4.52275 16.2691 4.22494C15.7558 3.92713 15.1234 3.92477 14.6078 4.21943L9.67373 7.03905C9.11184 7.36034 8.47553 7.52879 7.82824 7.52879H5.74133C5.01413 7.52879 4.31656 7.81794 3.80253 8.33209C3.28837 8.84625 3 9.54366 3 10.2709V14.5779C3 15.3051 3.28836 16.0027 3.80253 16.5167C4.31669 17.0309 5.0141 17.32 5.74133 17.32H7.82824C8.47553 17.32 9.11184 17.4885 9.67373 17.8098L14.6078 20.6294C15.1234 20.924 15.7559 20.9217 16.2691 20.6239C16.7825 20.326 17.0983 19.7775 17.0983 19.1843V5.66452ZM15.9228 5.66452V19.1844C15.9228 19.3584 15.8303 19.5199 15.679 19.6077C15.5285 19.6946 15.3428 19.6954 15.1908 19.6092L10.2568 16.7896C9.51699 16.3672 8.68011 16.1447 7.82824 16.1447H5.74133C5.32598 16.1447 4.92792 15.9794 4.63405 15.6863C4.34017 15.3924 4.17567 14.9944 4.17567 14.5782V12.4247V10.2712C4.17567 9.85506 4.34019 9.45704 4.63405 9.16317C4.92792 8.87008 5.32598 8.70479 5.74133 8.70479H7.82824C8.68007 8.70479 9.51709 8.48229 10.2568 8.05984L15.1908 5.24023C15.3429 5.15404 15.5287 5.15482 15.679 5.24181C15.8303 5.32957 15.9228 5.49055 15.9228 5.66452Z"
              fill="white"/>
        <path d="M15.9228 5.66452V19.1844C15.9228 19.3584 15.8303 19.5199 15.679 19.6077C15.5285 19.6946 15.3428 19.6954 15.1908 19.6092L10.2568 16.7896C9.51699 16.3672 8.68011 16.1447 7.82824 16.1447H5.74133C5.32598 16.1447 4.92792 15.9794 4.63405 15.6863C4.34017 15.3924 4.17567 14.9944 4.17567 14.5782V12.4247V10.2712C4.17567 9.85506 4.34019 9.45704 4.63405 9.16317C4.92792 8.87008 5.32598 8.70479 5.74133 8.70479H7.82824C8.68007 8.70479 9.51709 8.48229 10.2568 8.05984L15.1908 5.24023C15.3429 5.15404 15.5287 5.15482 15.679 5.24181C15.8303 5.32957 15.9228 5.49055 15.9228 5.66452Z"
              fill="white"/>
        <path d="M19 12.4247V8.55985C19 8.2837 19.2239 8.05984 19.5 8.05984C19.7761 8.05984 20 8.2837 20 8.55984V16.2896C20 16.5658 19.7761 16.7896 19.5 16.7896C19.2239 16.7896 19 16.5658 19 16.2896V12.4247Z"
              fill="white"/>
        <path d="M21 12.4247V7.55981C21 7.28367 21.2239 7.05981 21.5 7.05981C21.7761 7.05981 22 7.28367 22 7.55981V17.2896C22 17.5657 21.7761 17.7896 21.5 17.7896C21.2239 17.7896 21 17.5657 21 17.2896V12.4247Z"
              fill="white"/>
    </svg>
</template>

<script>
export default {
    name: "SpeakerIcon"
}
</script>

<style>

</style>
