<template>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_2865_4315)">
            <circle cx="10" cy="10" r="10" transform="rotate(-45 10 10)" fill="#0F243F"/>
            <path d="M15.6564 10.0004H4.34266" stroke="white" stroke-linecap="round"/>
        </g>
        <defs>
            <clipPath id="clip0_2865_4315">
                <rect width="20" height="20" fill="white"/>
            </clipPath>
        </defs>
    </svg>
</template>

<script>
export default {
    name: "RemoveFileIcon"
}
</script>

<style>

</style>
