<template>
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 8.49066L6.2919 3.75V8.49066H1Z" fill="white"/>
        <path d="M6.2919 21.2538H1.40707C1.18225 21.2538 1 21.0715 1 20.8467V16.6954H6.2919V21.2538Z" fill="white"/>
        <path d="M24.0001 6.70759V18.3077C24.0001 18.6323 23.6388 18.8263 23.3682 18.6471L19.5224 16.099L14.2305 12.5929L19.5224 8.98803L23.3639 6.37116C23.6341 6.18708 24.0001 6.38062 24.0001 6.70759Z"
              fill="white" fill-opacity="0.6"/>
        <path d="M19.5224 8.98816L14.2305 12.593L19.5224 16.0992V8.98816Z" fill="white" fill-opacity="0.6"/>
        <path d="M19.1157 21.2535H6.29297V16.6951H14.2308V12.5927L19.5227 16.0988V20.8464C19.5227 21.0712 19.3405 21.2535 19.1157 21.2535Z"
              fill="white"/>
        <path d="M6.2919 8.49023H1V16.6952H6.2919V8.49023Z" fill="white" fill-opacity="0.8"/>
        <path d="M6.29297 3.75H19.1157C19.3405 3.75 19.5227 3.93225 19.5227 4.15707V8.98828L14.2308 12.5932V8.49066H6.29297V3.75Z"
              fill="white"/>
    </svg>
</template>

<script>
export default {
    name: "AppointmentLeaveIcon"
}
</script>

<style>

</style>
