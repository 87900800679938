<template>
    <svg v-if="state" width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M14.8363 15.8362C14.1625 16.272 13.3597 16.5251 12.4981 16.5251C10.1175 16.5251 8.18555 14.593 8.18555 12.2126V9.1855L14.8363 15.8362ZM15.8835 14.8834C16.464 14.1488 16.8106 13.2209 16.8106 12.2126V9.05H10.05L15.8835 14.8834Z"
              fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M16.8105 8.47491H9.47496L8.18547 7.18542V5.31252C8.18547 2.93196 10.1174 1 12.498 1C14.8786 1 16.8105 2.93196 16.8105 5.31252V8.47491Z"
              fill="white"/>
        <path d="M8.18547 7.18542V8.47491H9.47496L8.18547 7.18542Z" fill="white"/>
        <path
                d="M16.4847 17.4847L16.8892 17.8891C17.2635 17.6 17.6086 17.275 17.9192 16.9191L17.5152 16.5152C17.2073 16.8736 16.8617 17.1989 16.4847 17.4847Z"
                fill="white"/>
        <path d="M10.05 9.05H8.18555V9.1855L14.8363 15.8362C15.2355 15.5779 15.5895 15.2554 15.8835 14.8834L10.05 9.05Z"
              fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M12.2107 19.3827V23.4251H7.89816C7.73721 23.4251 7.61072 23.5516 7.61072 23.7126C7.61053 23.8735 7.73702 24 7.89816 24H17.0984C17.2593 24 17.3858 23.8735 17.3858 23.7126C17.3858 23.5516 17.2593 23.4251 17.0982 23.4251H12.7857V19.3829C14.3266 19.3204 15.7446 18.7729 16.8892 17.8891L16.4847 17.4847C15.3755 18.3255 13.9939 18.825 12.4981 18.825C8.85258 18.825 5.88542 15.858 5.88542 12.2123V8.76224C5.88542 8.60129 5.75893 8.47481 5.59798 8.47481C5.43703 8.47481 5.31055 8.60129 5.31055 8.76224V12.2123C5.31055 16.0764 8.38101 19.2334 12.2107 19.3827ZM17.5152 16.5152L17.9192 16.9191C19.0189 15.6592 19.6857 14.0134 19.6857 12.2128V8.76274C19.6857 8.60179 19.5592 8.4753 19.3983 8.4753C19.2373 8.4753 19.1108 8.60179 19.1108 8.76274V12.2128C19.1108 13.8541 18.5093 15.3578 17.5152 16.5152Z"
              fill="white"/>
        <path d="M8.18555 9.05H10.05L9.47496 8.47491H8.18547L8.18555 9.05Z" fill="white"/>
        <path d="M10.05 9.05H16.8106L16.8105 8.47491H9.47496L10.05 9.05Z" fill="white"/>
    </svg>
    <svg v-if="!state" width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M14.8363 15.8362C14.1626 16.272 13.3597 16.5251 12.4981 16.5251C10.1176 16.5251 8.18562 14.593 8.18562 12.2126V9.1855L14.8363 15.8362ZM15.8835 14.8834C16.4641 14.1488 16.8107 13.2209 16.8107 12.2126V9.05H10.0501L15.8835 14.8834Z"
              fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M12.2108 23.4251V19.3827C8.38109 19.2334 5.31062 16.0764 5.31062 12.2123V8.76224C5.31062 8.60129 5.43711 8.47481 5.59806 8.47481C5.75901 8.47481 5.88549 8.60129 5.88549 8.76224V12.2123C5.88549 15.858 8.85266 18.825 12.4982 18.825C13.994 18.825 15.3755 18.3255 16.4848 17.4847L16.8892 17.8891C15.7447 18.7729 14.3266 19.3204 12.7857 19.3829V23.4251H17.0983C17.2594 23.4251 17.3859 23.5516 17.3859 23.7126C17.3859 23.8735 17.2594 24 17.0984 24H7.89823C7.73709 24 7.6106 23.8735 7.61079 23.7126C7.61079 23.5516 7.73728 23.4251 7.89823 23.4251H12.2108ZM17.9193 16.9191C17.6087 17.275 17.2636 17.6 16.8892 17.8891L23.5001 24.5C23.7762 24.7761 24.2239 24.7761 24.5001 24.5C24.7762 24.2238 24.7762 23.7761 24.5001 23.5L17.9193 16.9191ZM17.5153 16.5152C17.2074 16.8736 16.8618 17.1989 16.4848 17.4847L14.8363 15.8362C15.2356 15.5779 15.5896 15.2554 15.8835 14.8834L17.5153 16.5152ZM17.5153 16.5152L17.9193 16.9191C19.019 15.6592 19.6858 14.0134 19.6858 12.2128V8.76274C19.6858 8.60179 19.5593 8.4753 19.3983 8.4753C19.2374 8.4753 19.1109 8.60179 19.1109 8.76274V12.2128C19.1109 13.8541 18.5094 15.3578 17.5153 16.5152Z"
              fill="white"/>
        <path
                d="M16.4848 17.4847L16.8892 17.8891C17.2636 17.6 17.6087 17.275 17.9193 16.9191L17.5153 16.5152C17.2074 16.8736 16.8618 17.1989 16.4848 17.4847Z"
                fill="white"/>
        <path d="M10.0501 9.05H16.8107L16.8106 8.47491H9.47503L10.0501 9.05Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M8.18562 9.1855V9.05L8.18555 8.47491V7.18542L9.47503 8.47491H16.8106V5.31252C16.8106 2.93196 14.8786 1 12.4981 1C10.1175 1 8.18555 2.93196 8.18555 5.31252V7.18542L1.50008 0.499953C1.22393 0.223811 0.776218 0.223811 0.500076 0.499954C0.223933 0.776096 0.223933 1.22381 0.500076 1.49995L8.18562 9.1855Z"
              fill="white"/>
    </svg>
</template>

<script>
export default {
    props: {
        state: Boolean
    },
    name: "MicrophoneIcons"
}
</script>

<style>

</style>
