<template>
    <svg width="95" height="95" viewBox="0 0 95 95" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="47.5" cy="47.5" r="47.5" fill="url(#paint0_radial_3057_2302)"/>
        <rect x="25" y="25" width="45" height="45" rx="22.5" fill="black" fill-opacity="0.7"/>
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M49.8363 50.8362C49.1625 51.272 48.3597 51.5251 47.4981 51.5251C45.1175 51.5251 43.1855 49.593 43.1855 47.2126V44.1855L49.8363 50.8362ZM50.8835 49.8834C51.464 49.1488 51.8106 48.2209 51.8106 47.2126V44.05H45.05L50.8835 49.8834Z"
              fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M51.8105 43.4749H44.475L43.1855 42.1854V40.3125C43.1855 37.932 45.1174 36 47.498 36C49.8786 36 51.8105 37.932 51.8105 40.3125V43.4749Z"
              fill="white"/>
        <path d="M43.1855 42.1854V43.4749H44.475L43.1855 42.1854Z" fill="white"/>
        <path
                d="M51.4847 52.4847L51.8892 52.8891C52.2635 52.6 52.6086 52.275 52.9192 51.9191L52.5152 51.5152C52.2073 51.8736 51.8617 52.1989 51.4847 52.4847Z"
                fill="white"/>
        <path d="M45.05 44.05H43.1855V44.1855L49.8363 50.8362C50.2355 50.5779 50.5895 50.2554 50.8835 49.8834L45.05 44.05Z"
              fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M47.2107 54.3827V58.4251H42.8982C42.7372 58.4251 42.6107 58.5516 42.6107 58.7126C42.6105 58.8735 42.737 59 42.8982 59H52.0984C52.2593 59 52.3858 58.8735 52.3858 58.7126C52.3858 58.5516 52.2593 58.4251 52.0982 58.4251H47.7857V54.3829C49.3266 54.3204 50.7446 53.7729 51.8892 52.8891L51.4847 52.4847C50.3755 53.3255 48.9939 53.825 47.4981 53.825C43.8526 53.825 40.8854 50.858 40.8854 47.2123V43.7622C40.8854 43.6013 40.7589 43.4748 40.598 43.4748C40.437 43.4748 40.3105 43.6013 40.3105 43.7622V47.2123C40.3105 51.0764 43.381 54.2334 47.2107 54.3827ZM52.5152 51.5152L52.9192 51.9191C54.0189 50.6592 54.6857 49.0134 54.6857 47.2128V43.7627C54.6857 43.6018 54.5592 43.4753 54.3983 43.4753C54.2373 43.4753 54.1108 43.6018 54.1108 43.7627V47.2128C54.1108 48.8541 53.5093 50.3578 52.5152 51.5152Z"
              fill="white"/>
        <path d="M43.1855 44.05H45.05L44.475 43.4749H43.1855L43.1855 44.05Z" fill="white"/>
        <path d="M45.05 44.05H51.8106L51.8105 43.4749H44.475L45.05 44.05Z" fill="white"/>
        <defs>
            <radialGradient id="paint0_radial_3057_2302" cx="0" cy="0" :r="voice" gradientUnits="userSpaceOnUse"
                            gradientTransform="translate(47.5 47.5) rotate(90) scale(47.5)">
                <stop stop-color="#39C0ED"/>
                <stop offset="0.503306" stop-color="#39C0ED"/>
                <stop offset="0.67488" stop-color="#39C0ED" stop-opacity="0.4"/>
                <stop offset="0.852126" stop-color="#39C0ED" stop-opacity="0.1"/>
                <stop offset="1" stop-color="#39C0ED" stop-opacity="0"/>
            </radialGradient>
        </defs>
    </svg>

</template>

<script>
export default {
    props: {
        voiceLevel: 0
    },
    name: "TestMicrophoneIcon",
    computed: {
        voice() {
            return this.voiceLevel / 30
        }
    }
}
</script>

<style>
</style>
