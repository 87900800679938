<template>
    <svg v-if="colored" width="39" height="36" viewBox="0 0 39 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
                d="M9.5895 33.7427L19.1252 29.2025C19.3549 29.0933 19.6209 29.0927 19.85 29.2019L29.3857 33.7421L28.0301 23.2567C27.996 22.9989 28.0818 22.7404 28.2639 22.5548L35.5096 14.8781L25.1321 12.9357C24.8796 12.8846 24.6646 12.7207 24.5477 12.491L19.476 3.22253L14.4277 12.491C14.3114 12.7207 14.0958 12.8846 13.8433 12.9357L3.46582 14.8781L10.7344 22.5548C10.9076 22.7445 10.9858 23.0036 10.9452 23.2573L9.5895 33.7427Z"
                fill="#39C0ED"/>
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M8.44416 35.9893C7.99717 35.917 7.68705 35.5035 7.74285 35.053L9.23884 23.4446L1.19848 14.9022C0.988217 14.6766 0.918315 14.3536 1.01581 14.0605C1.11331 13.768 1.36294 13.5518 1.66601 13.4972L13.1417 11.344L18.751 1.0694C18.899 0.801577 19.1815 0.634766 19.4875 0.634766C19.7935 0.634766 20.0755 0.801577 20.2241 1.0694L25.8334 11.344L37.3327 13.4972C37.6357 13.5518 37.8854 13.768 37.9829 14.0605C38.0804 14.3536 38.0099 14.676 37.7996 14.9016L29.7592 23.4211L31.2552 35.0531C31.2922 35.3573 31.1624 35.658 30.9146 35.8378C30.6667 36.0181 30.3407 36.0492 30.0635 35.9194L19.4993 30.8875L8.9351 35.9194C8.78239 35.9911 8.61097 36.0157 8.44416 35.9893ZM19.1252 29.2025L9.5895 33.7427L10.9452 23.2573C10.9858 23.0036 10.9076 22.7445 10.7344 22.5548L3.46582 14.8781L13.8433 12.9357C14.0958 12.8846 14.3114 12.7207 14.4277 12.491L19.476 3.22253L24.5477 12.491C24.6646 12.7207 24.8796 12.8846 25.1321 12.9357L35.5096 14.8781L28.2639 22.5548C28.0818 22.7404 27.996 22.9989 28.0301 23.2567L29.3857 33.7421L19.85 29.2019C19.6209 29.0927 19.3549 29.0933 19.1252 29.2025Z"
              fill="#39C0ED"/>
    </svg>

    <svg v-else width="39" height="36" viewBox="0 0 39 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
                d="M8.44416 35.9893C7.99717 35.917 7.68705 35.5035 7.74285 35.053L9.23884 23.4446L1.19848 14.9022L1.19907 14.9016C0.988805 14.676 0.918315 14.3536 1.01581 14.0605C1.11331 13.768 1.36294 13.5518 1.66601 13.4972L13.1417 11.344L18.751 1.0694H18.7516C18.8996 0.801577 19.1815 0.634766 19.4875 0.634766C19.7935 0.634766 20.0755 0.801577 20.2241 1.0694L25.8334 11.344L37.3327 13.4972C37.6357 13.5518 37.8854 13.768 37.9829 14.0605C38.0804 14.3536 38.0099 14.676 37.7996 14.9016L29.7592 23.4211L31.2552 35.0531H31.2558C31.2928 35.3573 31.1624 35.658 30.9146 35.8378C30.6667 36.0181 30.3407 36.0492 30.0635 35.9194L19.4993 30.8875L8.9351 35.9194C8.78239 35.9911 8.61088 36.0157 8.44407 35.9893L8.44416 35.9893ZM9.5895 33.7427L19.1252 29.2025V29.2019C19.3549 29.0927 19.6209 29.0927 19.85 29.2019L29.3857 33.7421L28.0301 23.2567V23.2573C27.996 22.9995 28.0818 22.7404 28.2639 22.5548L35.5096 14.8781L25.1321 12.9357C24.8796 12.8846 24.6646 12.7207 24.5477 12.491L19.476 3.22253L14.4277 12.491C14.3114 12.7207 14.0958 12.8846 13.8433 12.9357L3.46582 14.8781L10.7344 22.5548H10.735C10.9082 22.7445 10.9858 23.0036 10.9452 23.2573L9.5895 33.7427Z"
                fill="black"/>
    </svg>
</template>

<script>
export default {
    props: {
        colored: false
    },
    name: "RatingStar"
}
</script>

<style>

</style>
