<template>
    <svg class="addFile" width="35" height="35" viewBox="0 0 35 35" fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <rect width="35" height="35" rx="17.5" fill="#0F243F"/>
        <path
                d="M14.9983 6.80971C13.3594 7.75586 12.35 9.50433 12.35 11.3968L12.35 24.7474L12.35 24.7475C12.3509 25.8483 12.7886 26.9037 13.5667 27.6823L13.5668 27.6823C14.3451 28.4606 15.4003 28.8987 16.5013 28.9H16.5015H16.5016H16.5019C17.6025 28.8979 18.6573 28.4599 19.4356 27.6816L19.4357 27.6816C20.2138 26.9032 20.6518 25.8482 20.6535 24.7476V24.7474V13.3216C20.6535 12.3056 20.1115 11.3666 19.2316 10.8587C18.3518 10.3508 17.2677 10.3508 16.3878 10.8587L16.3877 10.8587C15.5079 11.3667 14.9659 12.3056 14.9659 13.3216V24.515C14.962 24.6229 15.0018 24.7279 15.0764 24.806C15.1519 24.8849 15.2566 24.9298 15.3659 24.9298C15.4753 24.9298 15.5799 24.8849 15.6554 24.806C15.73 24.7279 15.7698 24.6229 15.7659 24.515V13.3216C15.7659 12.5914 16.1555 11.9167 16.7878 11.5516C17.4202 11.1866 18.1992 11.1866 18.8317 11.5516C19.4639 11.9167 19.8535 12.5914 19.8535 13.3216L19.8535 24.7474L19.8535 24.7492C19.8644 25.6451 19.516 26.5082 18.8864 27.1455C18.2567 27.783 17.3978 28.1418 16.5019 28.1418C15.6059 28.1418 14.7471 27.783 14.1173 27.1455C13.4878 26.5082 13.1393 25.6451 13.1502 24.7492V24.7474V11.3968C13.1502 9.79031 14.0072 8.30589 15.3985 7.50258C16.7899 6.69926 18.5038 6.69926 19.8951 7.50258C21.2864 8.30589 22.1434 9.79031 22.1434 11.3968V24.6205H22.1433L22.1436 24.6273C22.1533 24.8412 22.3294 25.0095 22.5434 25.0095C22.7574 25.0095 22.9336 24.8413 22.9433 24.6273L22.9435 24.6273V24.6205V11.3968C22.9435 9.50433 21.9341 7.75581 20.2951 6.80971C18.6562 5.86343 16.637 5.86342 14.9983 6.80971ZM14.9983 6.80971L15.0733 6.93962M14.9983 6.80971L15.0733 6.93962M15.0733 6.93962C13.4808 7.85897 12.5 9.55791 12.5 11.3968V24.7474L15.0733 6.93962Z"
                fill="white" stroke="white" stroke-width="0.3"/>
    </svg>
</template>

<script>
export default {
    name: "AddFileIcon",
    data() {
        return {}
    },
    methods: {}
}
</script>

<style>

</style>
