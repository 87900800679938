<template>
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M21.125 3.19043H3.875C2.29031 3.19043 1 4.47951 1 6.06543V15.4297C1 17.0156 2.29031 18.3047 3.875 18.3047H11.925V20.6594L7.8999 20.6596C7.58264 20.6596 7.32495 20.9171 7.32495 21.2346C7.32495 21.552 7.58265 21.8095 7.8999 21.8095H17.0999C17.4174 21.8095 17.6748 21.5518 17.6748 21.2346C17.6748 20.9171 17.4171 20.6596 17.0999 20.6596H13.075V18.3049H21.125C22.7097 18.3049 24 17.0158 24 15.4299V6.06563C24 4.47971 22.7097 3.19043 21.125 3.19043ZM22.8501 15.4297C22.8501 16.3807 22.0762 17.1548 21.125 17.1548H3.875C2.92404 17.1548 2.1499 16.3809 2.1499 15.4297V6.06543C2.1499 5.11447 2.92379 4.34033 3.875 4.34033H21.125C22.076 4.34033 22.8501 5.11422 22.8501 6.06543V15.4297Z"
              fill="white"/>
        <path d="M22.8501 15.4297C22.8501 16.3807 22.0762 17.1548 21.125 17.1548H3.875C2.92404 17.1548 2.1499 16.3809 2.1499 15.4297V6.06543C2.1499 5.11447 2.92379 4.34033 3.875 4.34033H21.125C22.076 4.34033 22.8501 5.11422 22.8501 6.06543V15.4297Z"
              fill="white"/>
    </svg>
</template>

<script>
export default {
    name: "ShareScreenIcons"
}
</script>

<style>

</style>
