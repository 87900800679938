<template>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="10" cy="10" r="7.5" stroke="black"/>
        <circle v-if="selected" cx="10" cy="10" r="4.5" fill="black"/>
    </svg>

</template>

<script>
export default {
    props: {
        selected: false
    },
    name: "RadioIcon"
}
</script>

<style scoped>

</style>
