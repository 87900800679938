<template>
    <svg v-if="!screenState" width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.5 6.99927H1.5L4 4.49927L6.5 1.99927V6.99927Z" fill="white"/>
        <path d="M1.5 1.99927L6.5 6.99927M6.5 6.99927H1.5L4 4.49927L6.5 1.99927V6.99927Z" stroke="white"/>
        <path d="M11.5 7L16.5 7L14 4.5L11.5 2L11.5 7Z" fill="white"/>
        <path d="M16.5 2L11.5 7M11.5 7L16.5 7L11.5 2L11.5 7Z" stroke="white"/>
        <path d="M11.5 12H16.5L14 14.5L11.5 17V12Z" fill="white"/>
        <path d="M16.5 17L11.5 12M11.5 12H16.5L11.5 17V12Z" stroke="white"/>
        <path d="M6.5 12L1.5 12L4 14.5L6.5 17L6.5 12Z" fill="white"/>
        <path d="M1.5 17L6.5 12M6.5 12L1.5 12L6.5 17L6.5 12Z" stroke="white"/>
    </svg>
    <svg v-else width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1H6L3.5 3.5L1 6V1Z" fill="white"/>
        <path d="M6 6L1 1M1 1H6L3.5 3.5L1 6V1Z" stroke="white"/>
        <path d="M16 1H11L13.5 3.5L16 6V1Z" fill="white"/>
        <path d="M11 6L16 1M16 1H11L16 6V1Z" stroke="white"/>
        <path d="M16 16L11 16L13.5 13.5L16 11L16 16Z" fill="white"/>
        <path d="M11 11L16 16M16 16L11 16L16 11L16 16Z" stroke="white"/>
        <path d="M1 16L6 16L3.5 13.5L1 11L1 16Z" fill="white"/>
        <path d="M6 11L1 16M1 16L6 16L1 11L1 16Z" stroke="white"/>
    </svg>
</template>

<script>
export default {
    props: {
        screenState: false
    },
    name: "ChangeViewScreen"
}
</script>

<style>

</style>
