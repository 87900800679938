<template>
  <div>
    <div v-if="!rating" class="appointmentCallPage__header">
      <div>
        {{ appointment.service_name }}
        <span
          >{{ appointment.consultation_name }},
          <duration-time :data="appointment.consultation_duration"
        /></span>
      </div>
      <div class="appointmentCallPage__header-time">
        {{ durationCall }}
      </div>
      <div v-if="!!timeToEnd" class="appointmentCallPage__timeToEnd">
        {{ $t("endAppointmentMessage") }}<span>{{ timeToEnd }}</span>
      </div>
    </div>
    <div v-if="session" class="appointmentCallPage__wrapper">
      <div
        v-if="!rating"
        id="subscriber"
        class="transition"
        :class="{
          appointmentCallPage__splitScreen: screenSplit,
          appointmentCallPage__shareScreen: !!shareScreenObj,
        }"
      >
        <div
          v-if="subscriber"
          class="transition appointmentCallPage__subscriber-bar"
        >
          <avatar
            v-if="userInformation.role_id === 4"
            user-role="experts"
            :user-code="appointment.expert_code"
            :image-name="appointment.expert_image"
            size="100"
            width="50px"
            height="50px"
          />
          <avatar
            v-if="userInformation.role_id === 2"
            user-role="employees"
            :user-code="appointment.employee_code"
            :image-name="appointment.employee_image"
            size="100"
            width="50px"
            height="50px"
          />
          <div
            v-if="userInformation.role_id === 4"
            class="appointmentCallPage__subscriberName"
          >
            {{
              `${appointment.expert_first_name} ${appointment.expert_last_name}`
            }}
          </div>
          <div
            v-if="userInformation.role_id === 2"
            class="appointmentCallPage__subscriberName"
          >
            {{
              `${appointment.employee_first_name} ${appointment.employee_last_name}`
            }}
          </div>
          <div
            class="appointmentCallPage__circleIcon appointmentCallPage__circleIcon-gray pointer-event-none mr-15"
          >
            <microphone-icons :state="subscriberAudioState" />
          </div>
          <div
            class="appointmentCallPage__circleIcon appointmentCallPage__circleIcon-gray pointer-event-none"
          >
            <camera-icons :state="subscriberVideoState" />
          </div>
        </div>
      </div>
      <div
        v-if="!rating"
        id="publisher"
        class="transition"
        :class="{ appointmentCallPage__splitScreen: screenSplit }"
      >
        <div
          v-if="userInformation.role_id === 4"
          class="transition appointmentCallPage__subscriber-bar d-none"
        >
          <avatar
            user-role="employees"
            :user-code="appointment.employee_code"
            :image-name="appointment.employee_image"
            size="100"
            width="50px"
            height="50px"
          />
          <div class="appointmentCallPage__subscriberName">
            (You)
            {{
              `${appointment.employee_first_name} ${appointment.employee_last_name}`
            }}
          </div>
        </div>
        <div
          v-if="userInformation.role_id === 2"
          class="transition appointmentCallPage__subscriber-bar d-none"
        >
          <avatar
            user-role="experts"
            :user-code="appointment.expert_code"
            :image-name="appointment.expert_image"
            size="100"
            width="50px"
            height="50px"
          />
          <div class="appointmentCallPage__subscriberName">
            (You)
            {{
              `${appointment.expert_first_name} ${appointment.expert_last_name}`
            }}
          </div>
        </div>
      </div>
      <div v-if="!rating" id="screenContainer"></div>
      <div v-if="chatVisible" class="appointmentCallPage__chat">
        <div class="appointmentCallPage__chatTop">
          <div
            v-if="userInformation.role_id === 4"
            class="d-flex align-items-center"
          >
            <avatar
              user-role="experts"
              :image-name="appointment.expert_image"
              :user-code="appointment.expert_code"
              size="100"
              height="50px"
              width="50px"
              class="w-auto"
            />
            <div class="ml-10">
              {{
                `${appointment.expert_first_name} ${appointment.expert_last_name}`
              }}
            </div>
          </div>
          <div
            v-if="userInformation.role_id === 2"
            class="d-flex align-items-center"
          >
            <avatar
              user-role="employees"
              :image-name="appointment.employee_image"
              :user-code="appointment.employee_code"
              size="100"
              height="50px"
              width="50px"
              class="w-auto"
            />
            <div class="ml-10">
              {{
                `${appointment.employee_first_name} ${appointment.employee_last_name}`
              }}
            </div>
          </div>
          <div class="cursor-pointer" @click="chatVisible = false">
            <svg
              width="30"
              height="30"
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 21L21 9"
                stroke="black"
                stroke-width="1.5"
                stroke-linecap="round"
              />
              <path
                d="M21 21L9 9"
                stroke="black"
                stroke-width="1.5"
                stroke-linecap="round"
              />
            </svg>
          </div>
        </div>
        <div id="scrollable" class="notificationPage__chatScroll">
          <chat-message
            v-for="activeChatMessage in chatInformation.messages"
            :key="activeChatMessage.id"
            :id="'t' + activeChatMessage.id"
            :upcoming="
              activeChatMessage.recipient_id === chatInformation.recipient_id
            "
            :chat-message="activeChatMessage"
            :filePath="chatInformation.file_upload_dir"
          />
        </div>
        <div class="notificationPage__inputs appointmentCallPage__chatFooter">
          <div class="notificationPage__fileContainer">
            <div
              v-for="(file, i) in files"
              :key="file.name"
              class="notificationPage__fileItem"
            >
              <div class="notificationPage__fileName">{{ file.name }}</div>
              <div
                @click="deleteFile(i)"
                class="notificationPage__fileRemoveIcon"
              >
                <remove-file-icon />
              </div>
            </div>
          </div>
          <label for="files">
            <add-file-icon />
          </label>
          <input
            type="file"
            name="files"
            id="files"
            ref="files"
            v-on:change="handleFileUpload()"
            class="d-none"
            multiple
          />
          <input
            :placeholder="$t('notificationsPageMessageInput')"
            @keyup.enter="sendMessage(message)"
            v-model="message"
            type="text"
          />
          <div
            class="notificationPage__inputs-arrow appointmentCallPage__chatInput-arrow"
            @click="sendMessage(message)"
          >
            <horizontal-arrow />
          </div>
        </div>
      </div>
    </div>
    <div v-if="rating" class="appointmentCallPage__ratingContainer">
      <div class="appointmentCallPage__ratingWrapper">
        <div class="appointmentCallPage__ratingService">
          {{ appointment.service_name }}
        </div>
        <div class="appointmentCallPage__ratingType">
          {{ appointment.consultation_name }},
          <span
            ><duration-time :data="appointment.consultation_duration"
          /></span>
        </div>
        <div class="d-flex align-items-center justify-content-between">
          <div
            v-if="userInformation.role_id === 4"
            class="d-flex align-items-center"
          >
            <avatar
              user-role="experts"
              :image-name="appointment.expert_image"
              :user-code="appointment.expert_code"
              size="100"
              height="50px"
              width="50px"
            />
            <div class="ml-10">
              <div class="appointmentCallPage__ratingName">
                {{
                  `${appointment.expert_first_name} ${appointment.expert_last_name}`
                }}
              </div>
            </div>
          </div>
          <div
            v-if="userInformation.role_id === 2"
            class="d-flex align-items-center"
          >
            <avatar
              user-role="employees"
              :image-name="appointment.employee_image"
              :user-code="appointment.employee_code"
              size="100"
              height="50px"
              width="50px"
            />
            <div class="ml-10">
              <div class="appointmentCallPage__ratingName">
                {{
                  `${appointment.employee_first_name} ${appointment.employee_last_name}`
                }}
              </div>
              <div class="appointmentCallPage__ratingPosition">
                {{ appointment.expert_position }}
              </div>
            </div>
          </div>
          <div>
            {{ durationCall }}
          </div>
        </div>
      </div>
      <div class="appointmentCallPage__ratingWrapper">
        <div class="appointmentCallPage__ratingTitle">
          {{ $t("finishedAppointment") }}
        </div>
        <div
          v-if="currentUser.role_id === 4"
          class="appointmentCallPage__ratingQuestion"
        >
          {{ $t("effectiveAppointment") }}
        </div>
        <div
          v-if="currentUser.role_id === 4"
          class="d-flex align-items-center justify-content-center mb-30"
        >
          <div
            class="cursor-pointer"
            v-for="n in 5"
            @click="ratingValue.ratingAppointment = n"
          >
            <rating-star :colored="ratingValue.ratingAppointment >= n" />
          </div>
        </div>
        <div class="appointmentCallPage__ratingQuestion">
          {{ $t("qualityCall") }}
        </div>
        <div class="d-flex align-items-center justify-content-center mb-30">
          <div
            class="cursor-pointer"
            v-for="n in 5"
            @click="ratingValue.ratingCall = n"
          >
            <rating-star :colored="ratingValue.ratingCall >= n" />
          </div>
        </div>
        <div
          class="d-flex justify-content-center"
          :class="{ 'justify-content-between': currentUser.role_id === 2 }"
        >
          <base-button link="/" class="appointmentCallPage__ratingBtn">
            {{ $t("backToPersonalCabinet") }}
          </base-button>
          <base-button
            v-if="currentUser.role_id === 2"
            @click="endAppointment()"
            class="appointmentCallPage__ratingBtn position-relative"
          >
            <span class="tooltip-text">
              {{ $t("endAppointmentTooltip") }}
            </span>
            {{ $t("endAppointment") }}
          </base-button>
        </div>
      </div>
    </div>
    <div v-if="!session" id="preview"></div>
    <div v-if="!session" class="appointmentCallPage__settingsContainer">
      <div class="appointmentCallPage__settingsTitle">
        {{ $t("callSettings") }}
      </div>
      <div class="appointmentCallPage__settingsSteps">
        <div
          @click="changeStep('audioInput')"
          class="appointmentCallPage__settingsStep"
          :class="{
            'appointmentCallPage__settingsStep-active': settingStep.audioInput,
          }"
        >
          {{ $t("audioInput") }}
        </div>
        <div
          @click="changeStep('audioOutput')"
          class="appointmentCallPage__settingsStep"
          :class="{
            'appointmentCallPage__settingsStep-active': settingStep.audioOutput,
          }"
        >
          {{ $t("audioOutput") }}
        </div>
        <div
          @click="changeStep('video')"
          class="appointmentCallPage__settingsStep"
          :class="{
            'appointmentCallPage__settingsStep-active': settingStep.video,
          }"
        >
          {{ $t("video") }}
        </div>
      </div>
      <div
        v-if="settingStep.audioInput"
        class="appointmentCallPage__settingsWrapper"
      >
        <div class="mb-10">
          <test-microphone-icon :voice-level="voice" />
        </div>
        <vue-multiselect
          class="appointmentCallPage__multiselect"
          v-model="audioInputDeviceDefault"
          :options="audioInputDevice"
          :placeholder="$t('builtInMicrophone')"
          :show-labels="false"
          label="label"
          track-by="label"
        >
        </vue-multiselect>
        <div class="d-flex align-items-center justify-content-center mt-30">
          <button
            @click="changeStep('audioOutput')"
            class="appointmentCallPage__settingsStepBtn"
          >
            {{ $t("next") }}
          </button>
        </div>
      </div>
      <div
        v-if="settingStep.audioOutput"
        class="appointmentCallPage__settingsWrapper"
      >
        <button @click="speakerTest()" class="appointmentCallPage__speakerBtn">
          <speaker-icon />
          {{ $t("speakerTest") }}
        </button>
        <vue-multiselect
          class="appointmentCallPage__multiselect"
          v-model="audioOutputDeviceDefault"
          :options="audioOutputDevice"
          :placeholder="$t('builtInSound')"
          :show-labels="false"
          label="label"
          track-by="label"
        >
        </vue-multiselect>
        <div
          @click="changeStep('audioInput')"
          class="appointmentCallPage__settingsStepBtnBack"
        >
          <svg
            class="me-1"
            width="10"
            height="12"
            viewBox="0 0 10 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.39014 0.887939H5.93414L0.414135 6.33594L5.93414 11.7839H9.39014L3.84614 6.33594L9.39014 0.887939Z"
              fill="white"
            />
          </svg>
          {{ $t("back") }}
        </div>
        <button
          @click="changeStep('video')"
          class="appointmentCallPage__settingsStepBtn"
        >
          {{ $t("next") }}
        </button>
      </div>
      <div
        v-if="settingStep.video"
        class="appointmentCallPage__settingsWrapper"
      >
        <button class="appointmentCallPage__circleIcon mb-30" @click="video()">
          <camera-icons :state="videoState" />
        </button>
        <vue-multiselect
          class="appointmentCallPage__multiselect"
          @close="setCameraInputDevice(cameraDeviceDefault)"
          v-model="cameraDeviceDefault"
          :options="cameraDevices"
          :placeholder="$t('builtInCamera')"
          :show-labels="false"
          label="label"
          track-by="label"
        >
        </vue-multiselect>
        <div
          @click="changeStep('audioOutput')"
          class="appointmentCallPage__settingsStepBtnBack"
        >
          <svg
            class="me-1"
            width="10"
            height="12"
            viewBox="0 0 10 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.39014 0.887939H5.93414L0.414135 6.33594L5.93414 11.7839H9.39014L3.84614 6.33594L9.39014 0.887939Z"
              fill="white"
            />
          </svg>
          {{ $t("back") }}
        </div>
        <button
          @click="startStream()"
          class="appointmentCallPage__settingsStepBtn"
        >
          {{ $t("startAppointment") }}
        </button>
      </div>
    </div>
    <div v-if="!rating" class="appointmentCallPage__footerContainer">
      <div class="appointmentCallPage__footer">
        <div v-if="session" class="d-flex">
          <button
            class="appointmentCallPage__btn mr-15"
            :class="{ 'appointmentCallPage__btn-active': !audioState }"
            @click="audio()"
          >
            <microphone-icons :state="audioState" />
            <span class="desktop">
              {{ audioState ? $t("mute") : $t("unmute") }}
            </span>
          </button>
          <button
            class="appointmentCallPage__btn mr-15"
            :class="{ 'appointmentCallPage__btn-active': !videoState }"
            @click="video()"
          >
            <camera-icons :state="videoState" />
            <span class="desktop">
              {{ videoState ? $t("stopVideo") : $t("onVideo") }}
            </span>
          </button>
          <div class="position-relative">
            <button
              @click="showDevicesMenu = !showDevicesMenu"
              class="appointmentCallPage__circleIcon"
            >
              <setting-icon />
            </button>
            <div
              v-if="showDevicesMenu"
              class="appointmentCallPage__deviceSettings"
            >
              <div class="appointmentCallPage__deviceSettingsHeader">
                <div>{{ $t("audio") }}</div>
                <div
                  class="cursor-pointer"
                  @click="showDevicesMenu = !showDevicesMenu"
                >
                  <close-devices-menu-icon />
                </div>
              </div>
              <div class="appointmentCallPage__deviceSettingsAudio">
                <div class="mb-15">
                  <div class="opacity-50 mb-10">{{ $t("input") }}:</div>
                  <div v-for="item in audioInputDevice">
                    <div class="appointmentCallPage__deviceSettingsAudioItem">
                      <div
                        @click="setAudioInputDevice(item)"
                        class="cursor-pointer"
                      >
                        <radio-icon
                          :selected="
                            audioInputDeviceDefault.label === item.label
                          "
                        />
                      </div>
                      {{ item.label }}
                    </div>
                  </div>
                </div>
                <div>
                  <div class="opacity-50 mb-10">{{ $t("Output") }}:</div>
                  <div
                    v-for="item in audioOutputDevice"
                    class="appointmentCallPage__deviceSettingsAudioItem"
                  >
                    <div
                      @click="setAudioOutputDevice(item)"
                      class="cursor-pointer"
                    >
                      <radio-icon
                        :selected="
                          audioOutputDeviceDefault.deviceId === item.deviceId
                        "
                      />
                    </div>
                    {{ item.label }}
                  </div>
                </div>
              </div>
              <div class="appointmentCallPage__deviceSettingsVideo">
                <div class="mb-10">{{ $t("Camera") }}</div>
                <div class="ml-15">
                  <div v-for="item in cameraDevices">
                    <div class="appointmentCallPage__deviceSettingsAudioItem">
                      <div
                        @click="setCameraInputDevice(item)"
                        class="cursor-pointer"
                      >
                        <radio-icon
                          :selected="
                            item.deviceId === cameraDeviceDefault.deviceId
                          "
                        />
                      </div>
                      {{ item.label }}
                    </div>
                  </div>
                </div>
                <div class="ml-15">
                  <div class="appointmentCallPage__deviceSettingsEffects">
                    {{ $t("Effects") }}:
                  </div>
                  <div
                    class="appointmentCallPage__deviceSettingsAudioItem mb-10"
                  >
                    <label class="switch">
                      <input v-model="bgBlur" type="checkbox" />
                      <span class="slider round"></span>
                    </label>
                    {{ $t("BlurBackground") }}
                  </div>
                  <div
                    @click="
                      bgImagePopup = !bgImagePopup;
                      showDevicesMenu = !showDevicesMenu;
                    "
                    class="appointmentCallPage__deviceSettingsAudioItem cursor-pointer"
                  >
                    {{ $t("SelectBackground") }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="session" class="appointmentCallPage__footer-center">
          <button
            @click="screenSplit = !screenSplit"
            class="appointmentCallPage__circleIcon appointmentCallPage__circleIcon-gray mr-15"
          >
            <change-view-screen :screen-state="screenSplit" />
          </button>
          <button
            class="appointmentCallPage__btn mr-15"
            :class="{ 'appointmentCallPage__btn-active': !!shareScreenState }"
            @click="shareScreen()"
          >
            <share-screen-icons />
            <span class="desktop">
              {{ shareScreenState ? $t("stopSharing") : $t("shareScree") }}
            </span>
          </button>
          <button
            @click="chatVisible = !chatVisible"
            class="appointmentCallPage__btn"
          >
            <chat-icons />
            <span class="desktop">
              {{ $t("chat") }}
            </span>
          </button>
        </div>
        <div v-if="session" class="d-flex position-relative">
          <div
            v-if="afterLeavePopup"
            class="appointmentCallPage__afterLeavePopup"
          >
            <div class="appointmentCallPage__afterLeavePopupTitle">
              {{ $t("appointmentLeaveTitle") }}
            </div>
            <button
              @click="leaveAppointment()"
              class="appointmentCallPage__btn appointmentCallPage__btn-end"
            >
              <appointment-leave-icon />
              {{ $t("leaveAppointment") }}
            </button>
          </div>
          <button
            class="appointmentCallPage__btn appointmentCallPage__btn-cancel"
            @click="afterLeavePopup = !afterLeavePopup"
          >
            {{ $t("cancel") }}
          </button>
        </div>
      </div>
      <a href="/">
        <button
          v-if="!session"
          class="appointmentCallPage__btn appointmentCallPage__btn-end"
        >
          {{ $t("leave") }}
        </button>
      </a>
    </div>
    <div v-if="bgImagePopup" class="appointmentCallPage__bgPopup">
      <div class="position-relative">
        <div
          @click="bgImagePopup = !bgImagePopup"
          class="appointmentCallPage__bgPopupClose"
        >
          <svg
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_4226_6357)">
              <path
                d="M9 21L21 9"
                stroke="black"
                stroke-width="1.5"
                stroke-linecap="round"
              />
              <path
                d="M21 21L9 9"
                stroke="black"
                stroke-width="1.5"
                stroke-linecap="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_4226_6357">
                <rect width="30" height="30" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
        <h5>
          {{ $t("SelectBackground") }}
        </h5>
        <p>
          <span>
            {{ appointment.consultation_name }},
            <duration-time :data="appointment.consultation_duration" />
          </span>
        </p>
        <div class="appointmentCallPage__bgPopupImageWrapper">
          <div
            v-for="image in images"
            @click="setImageBg(image)"
            class="appointmentCallPage__bgPopupImage"
          >
            <picture>
              <source
                type="image/webp"
                :srcset="require(`@/assets/backgrounds/${image}.webp`)"
              />
              <img
                :src="require(`@/assets/backgrounds/${image}.png`)"
                :alt="image"
              />
            </picture>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueMultiselect from "vue-multiselect";
import OT from "@opentok/client";
import { toRaw } from "vue";
import SettingIcon from "@/components/UI/icons/appointmentIcon/SettingIcon";
import ChangeViewScreen from "@/components/UI/icons/appointmentIcon/ChangeViewScreen";
import MicrophoneIcons from "@/components/UI/icons/appointmentIcon/MicrophoneIcons";
import CameraIcons from "@/components/UI/icons/appointmentIcon/CameraIcons";
import ShareScreenIcons from "@/components/UI/icons/appointmentIcon/ShareScreenIcons";
import ChatIcons from "@/components/UI/icons/appointmentIcon/ChatIcons";
import { intervalToDuration } from "date-fns";
import Avatar from "@/components/UI/images/Avatar";
import RadioIcon from "@/components/UI/icons/appointmentIcon/RadioIcon";
import CloseDevicesMenuIcon from "@/components/UI/icons/appointmentIcon/CloseDevicesMenuIcon";
import AppointmentLeaveIcon from "@/components/UI/icons/appointmentIcon/AppointmentLeaveIcon";
import SpeakerIcon from "@/components/UI/icons/appointmentIcon/SpeakerIcon";
import TestMicrophoneIcon from "@/components/UI/icons/appointmentIcon/TestMicrophoneIcon";
import DurationTime from "@/components/components-helpers/DurationTime";
import { mapGetters } from "vuex";
import ChatMessage from "@/components/elements/card/ChatMessage";
import RemoveFileIcon from "@/components/UI/icons/addRemoveIcons/RemoveFileIcon";
import AddFileIcon from "@/components/UI/icons/AddFileIcon";
import HorizontalArrow from "@/components/UI/icons/arrows/HorizontalArrow";
import MainLayout from "@/layouts/MainLayout";
import BaseButton from "@/components/UI/buttons/BaseButton";
import RatingStar from "@/components/UI/icons/appointmentIcon/RatingStar";
import { useToast } from "vue-toastification";
import ClosePopupIcon from "@/components/UI/icons/addRemoveIcons/ClosePopupIcon";

export default {
  name: "AppointmentCallPage",
  components: {
    ClosePopupIcon,
    RatingStar,
    BaseButton,
    MainLayout,
    HorizontalArrow,
    AddFileIcon,
    RemoveFileIcon,
    ChatMessage,
    DurationTime,
    TestMicrophoneIcon,
    SpeakerIcon,
    VueMultiselect,
    AppointmentLeaveIcon,
    CloseDevicesMenuIcon,
    RadioIcon,
    Avatar,
    ChatIcons,
    ShareScreenIcons,
    CameraIcons,
    MicrophoneIcons,
    ChangeViewScreen,
    SettingIcon,
  },
  setup() {
    const toast = useToast();
    return {
      toast,
    };
  },
  data() {
    return {
      afterLeavePopup: false,
      bgBlur: false,
      bgImagePopup: false,
      images: [],
      shareScreenObj: null,
      durationCall: "",
      timeToEnd: null,
      tokenLifeTime: null,
      rating: false,
      ratingValue: {
        ratingCall: null,
        ratingAppointment: null,
      },
      message: "",
      files: "",
      filesName: [],
      chatVisible: false,
      settingStep: {
        audioInput: true,
        audioOutput: false,
        video: false,
      },
      showDevicesMenu: false,
      session: null,
      publisher: null,
      subscriber: null,
      token: null,
      apiKey: process.env.VUE_APP_VONAGE_API_KEY,
      audioState: true,
      videoState: true,
      subscriberAudioState: true,
      subscriberVideoState: true,
      shareScreenState: false,
      screenSplit: false,
      interval: 0,
      audioInputDevice: [],
      audioOutputDevice: [],
      cameraDevices: [],
      audioInputDeviceDefault: [],
      audioOutputDeviceDefault: [],
      cameraDeviceDefault: [],
      javascriptNode: null,
      voice: 0,
      appointment: {},
      mediaDevices: null,
      incomingScreen: false,
    };
  },
  mounted() {
    window.setInterval(() => {
      this.interval = new Date();
    }, 1000);
    this.shareMediaDevicesCheck();
    this.importBackgroundImage(
      require.context("@/assets/backgrounds/", true, /\.png$/)
    );
  },
  methods: {
    importBackgroundImage(images) {
      images.keys().forEach((key) => {
        let name = key.slice(2);
        name = name.split(".")[0];
        this.images.push(name);
      });
    },
    async setBlur(blurState) {
      if (blurState) {
        await this.publisher.applyVideoFilter({
          type: "backgroundBlur",
          blurStrength: "low",
        });
      } else {
        await this.publisher.clearVideoFilter();
      }
    },
    async setImageBg(imageName) {
      this.bgImagePopup = false;
      if (imageName === "bg-00") {
        await this.publisher.clearVideoFilter();
        return;
      }
      const pathToImage = await import(
        `@/assets/backgrounds/${imageName}.png`
      ).then((module) => {
        return module;
      });
      await this.publisher.applyVideoFilter({
        type: "backgroundReplacement",
        backgroundImgUrl: pathToImage.default,
      });
    },
    async shareMediaDevicesCheck() {
      let stream = null;
      try {
        stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: true,
        });
        window.localStream = stream;
        await this.beforeStreamStartSteps();
      } catch (err) {
        console.log(err);
      }
    },
    async getAppointmentInformation() {
      this.appointment = await this.$http
        .get(`appointments/code/${this.$route.params.appointment}`)
        .then(({ data }) => {
          return data.body;
        });

      if (this.currentUser.role_id === 4) {
        this.ratingValue.ratingCall = this.appointment.employee_call_quality;
        this.ratingValue.ratingAppointment = this.appointment.service_quality;
      }
      if (this.currentUser.role_id === 2) {
        this.ratingValue.ratingCall = this.appointment.expert_call_quality;
      }
      this.showChat();
    },
    changeStep(step) {
      for (let key in this.settingStep) {
        this.settingStep[key] = key === step;
      }
    },
    async beforeStreamStartSteps() {
      this.getAppointmentInformation();
      OT.getDevices((err, devices) => {
        this.audioInputDevice = devices.filter((item) => {
          return (
            item.kind === "audioInput" && item.deviceId !== "communications"
          );
        });
        this.audioInputDeviceDefault = this.audioInputDevice[0];
        this.cameraDevices = devices.filter((item) => {
          return item.kind === "videoInput";
        });
        this.cameraDeviceDefault = this.cameraDevices[0];
      });
      this.audioOutputDevice = await OT.getAudioOutputDevices();
      this.audioOutputDevice = this.audioOutputDevice.filter(
        (device) => device.deviceId !== "communications"
      );
      this.audioOutputDeviceDefault = this.audioOutputDevice[0];
      await this.initPublisher("preview", {
        insertMode: "append",
        width: "100%",
        height: "100%",
      });
      this.microphoneTest();
    },
    startStream() {
      this.publisher.destroy();
      this.initializeSession();
    },
    audio() {
      this.audioState = !this.audioState;
      this.publisher.publishAudio(this.audioState);
    },
    video() {
      this.videoState = !this.videoState;
      this.publisher.publishVideo(this.videoState);
    },
    streamCreatedCallback(event) {
      let sessionUsers = event.stream.connection.data.split(",");
      if (sessionUsers[0].includes(this.currentUser.id, 1)) {
        this.session.forceDisconnect(event.stream.connection.connectionId);
        return;
      }
      if (event.stream.videoType === "screen") {
        this.incomingScreen = true;
        this.shareScreenObj = toRaw(this.session).subscribe(
          event.stream,
          "screenContainer",
          {
            insertMode: "append",
            width: "100%",
            height: "100%",
          },
          (e) => {
            console.log(`stream created error. ${e}`);
          }
        );
        return;
      }
      if (!this.subscriber) {
        this.subscriber = toRaw(this.session).subscribe(
          event.stream,
          "subscriber",
          {
            insertMode: "append",
            width: "100%",
            height: "100%",
          },
          (e) => {
            console.log(`stream created error. ${e}`);
          }
        );
      }
    },
    streamDestroyedCallback(event) {
      if (event.stream.videoType === "screen") {
        this.shareScreenObj = null;
        this.incomingScreen = false;
      }
    },
    streamPropertyChangedCallback(event) {
      let userRole = event.stream.connection.data.split(",")[1];
      if (!userRole.includes(this.currentUser.role_id, 1)) {
        if (event.changedProperty === "hasAudio") {
          this.subscriberAudioState = event.newValue;
        } else if (event.changedProperty === "hasVideo") {
          this.subscriberVideoState = event.newValue;
        }
      }
    },
    async initPublisher(targetElement, publisherOptions) {
      if (this.publisher) toRaw(this.session).unpublish(this.publisher);
      this.publisher = OT.initPublisher(
        targetElement,
        publisherOptions,
        this.initPublisherCallback
      );
    },
    initPublisherCallback(error) {
      if (error) {
        console.log(`Init publisher error. ${error}`);
      } else {
        if (this.session) {
          toRaw(this.session).publish(this.publisher, function (error) {
            if (error) {
              console.log(`Publish stream error. ${error}`);
            }
          });
        }
      }
    },
    connectCallback(error) {
      if (error) {
        console.log("Error connecting: ", error.name, error.message);
      } else {
        console.log("Connected to the session.");
        this.initPublisher("publisher", {
          insertMode: "append",
          videoSource: this.cameraDeviceDefault.deviceId,
          audioSource: this.audioInputDeviceDefault.deviceId,
          width: "100%",
          height: "100%",
        });
        this.setAudioOutputDevice(this.audioOutputDeviceDefault);
        if (!this.videoState) this.publisher.publishVideo(false);
      }
    },
    shareScreen() {
      if (this.incomingScreen) return;
      this.shareScreenState = !this.shareScreenState;
      if (this.shareScreenState) {
        if (!!this.shareScreenObj) return;
        const shareScreenCallback = (response) => {
          if (!response.supported || response.extensionRegistered === false) {
            console.log("Sharing screen not supported");
          } else if (response.extensionInstalled === false) {
          } else {
            this.shareScreenObj = OT.initPublisher("screenContainer", {
              insertMode: "append",
              width: "100%",
              height: "100%",
              videoSource: "screen",
            });
            toRaw(this.session).publish(this.shareScreenObj);
          }
        };
        OT.checkScreenSharingCapability(shareScreenCallback);
      } else {
        toRaw(this.session).unpublish(this.shareScreenObj);
        this.shareScreenObj = null;
      }
    },
    async initializeSession() {
      const sessionInformation = await this.$http
        .post(`appointments/${this.appointment.id}/session`)
        .then((data) => {
          return data.data.body;
        });
      await this.$http.patch(`appointments/${this.appointment.id}/status/2`);
      let sessionId = sessionInformation.sessionId;
      this.token = sessionInformation.token;
      this.session = OT.initSession(this.apiKey, sessionId);
      this.session.on("streamCreated", this.streamCreatedCallback);
      this.session.on("streamDestroyed", this.streamDestroyedCallback);
      this.session.on(
        "streamPropertyChanged",
        this.streamPropertyChangedCallback
      );
      await this.session.connect(this.token, this.connectCallback);
    },
    async setAudioOutputDevice(data) {
      await OT.setAudioOutputDevice(data.deviceId);
      this.audioOutputDeviceDefault = await OT.getActiveAudioOutputDevice();
    },
    async setCameraInputDevice(data) {
      await this.publisher.setVideoSource(data.deviceId);
      this.cameraDeviceDefault = this.cameraDevices.filter(
        (device) => device.deviceId === this.publisher.getVideoSource().deviceId
      )[0];
    },
    async setAudioInputDevice(data) {
      await this.publisher.setAudioSource(data.deviceId);
      this.audioInputDeviceDefault = this.publisher.getAudioSource();
    },
    async leaveAppointment() {
      window.localStream.getTracks().forEach((track) => {
        track.stop();
      });
      this.rating = true;
      this.afterLeavePopup = false;
      this.chatVisible = false;
      this.publisher.destroy();
      this.session.disconnect();
      if (!!this.subscriber) this.subscriber.destroy();
      if (this.interval) clearInterval(this.interval);
    },
    endAppointment() {
      this.$http
        .post(`appointments/${this.appointment.id}/session/end`)
        .then(() => {
          this.$router.push("/");
        })
        .catch((err) => {
          console.log(err.response.data.message);
        });
    },
    speakerTest() {
      import("@/assets/sound/sound.mp3").then((module) => {
        const audio = new Audio(module.default);
        audio.play();
      });
    },
    microphoneTest() {
      navigator.getUserMedia =
        navigator.getUserMedia ||
        navigator.webkitGetUserMedia ||
        navigator.mozGetUserMedia;
      if (navigator.getUserMedia) {
        navigator.getUserMedia(
          {
            audio: true,
          },
          (stream) => {
            let audioContext = new AudioContext();
            let analyser = audioContext.createAnalyser();
            let microphone = audioContext.createMediaStreamSource(stream);
            this.javascriptNode = audioContext.createScriptProcessor(512, 1, 1);

            analyser.smoothingTimeConstant = 0.8;
            analyser.fftSize = 1024;

            microphone.connect(analyser);
            analyser.connect(this.javascriptNode);
            this.javascriptNode.connect(audioContext.destination);
            this.javascriptNode.onaudioprocess = () => {
              let array = new Uint8Array(analyser.frequencyBinCount);
              analyser.getByteFrequencyData(array);
              let values = 0;
              let length = array.length;
              for (let i = 0; i < length; i++) {
                values += array[i];
              }
              this.voice = values / length;
            };
          },
          (err) => {
            console.log("The following error occured: " + err.name);
          }
        );
      } else {
        console.log("getUserMedia not supported");
      }
    },
    appointmentDurationTimer(data) {
      if (!!!this.session) return;
      if (!!this.appointment.end_time) {
        const sessionLifeTime =
          new Date().getTime() > new Date(this.appointment.end_time).getTime();
        if (sessionLifeTime) {
          this.leaveAppointment();
          this.$http
            .post(`appointments/${this.appointment.id}/session/end`)
            .then(() => {})
            .catch((err) => {
              console.log(err.response.data.message);
            });
          return;
        }
        if (new Date(this.appointment.time).getTime() > new Date().getTime())
          return;
        let timeToEnd = intervalToDuration({
          start: new Date(),
          end: new Date(this.appointment.end_time),
        });
        if (!!this.appointment.time && !this.rating && !sessionLifeTime) {
          let duration = intervalToDuration({
            start: new Date(this.appointment.time),
            end: new Date(data),
          });
          let durationHours = new Intl.NumberFormat("en-IN", {
            minimumIntegerDigits: 2,
          }).format(duration.hours);
          let durationMinutes = new Intl.NumberFormat("en-IN", {
            minimumIntegerDigits: 2,
          }).format(duration.minutes);
          let hoursToEnd = new Intl.NumberFormat("en-IN", {
            minimumIntegerDigits: 2,
          }).format(timeToEnd.hours);
          let minutesToEnd = new Intl.NumberFormat("en-IN", {
            minimumIntegerDigits: 2,
          }).format(timeToEnd.minutes);
          let secondsToEnd = new Intl.NumberFormat("en-IN", {
            minimumIntegerDigits: 2,
          }).format(timeToEnd.seconds);
          this.timeToEnd =
            minutesToEnd < 10 && hoursToEnd < 1
              ? `${minutesToEnd}:${secondsToEnd}`
              : null;
          this.durationCall = `${durationHours}:${durationMinutes}`;
        }
      }
    },
    setLocale(locale) {
      import(`@/languages/${locale}.json`).then((msgs) => {
        this.$i18n.setLocaleMessage(locale, msgs);
        this.$i18n.locale = locale;
      });
    },
    sendMessage(text) {
      if (text.length > 0 || this.filesName.length > 0) {
        this.$http
          .post("messages", {
            chat_id: this.chatInformation.id,
            recipient_id: this.chatInformation.recipient_id,
            text: text,
            files: this.filesName,
          })
          .then(() => {
            this.showChat();
            this.message = "";
            this.files = [];
            this.filesName = [];
          });
      }
    },
    uploadFiles(file) {
      for (let i = 0; i < file.length; i++) {
        let formData = new FormData();
        formData.append("file", file[i]);
        this.$http
          .post("uploads/file", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((data) => {
            this.filesName.push(data.data.body);
          });
      }
    },
    handleFileUpload() {
      this.files = this.$refs.files.files;
      this.uploadFiles(this.files);
    },
    deleteFile(index) {
      let file = [];
      for (let i in this.files) {
        if (typeof this.files[i] === "object") {
          file.push(this.files[i]);
        }
      }
      file = file.filter((item, i) => i !== index);
      this.files = file;
    },
    scrollBottom() {
      let lastItem = this.chatInformation.messages.at(-1);
      if (lastItem && this.chatVisible) {
        let itemId = "t" + lastItem.id;
        setTimeout(() => {
          let element = document.getElementById(itemId);
          element.scrollIntoView({ behavior: "smooth" });
        }, 500);
        this.$socket.emit("read-message", {
          chatId: this.chatInformation.id,
          recipientId: this.chatInformation.recipient_id,
        });
      }
    },
    showChat() {
      this.$http
        .get(
          `chats/expert/${this.appointment.expert_id}/employee/${this.appointment.employee_id}`
        )
        .then(({ data }) => {
          let chatInformation = data.body;
          chatInformation.recipient_id = this.currentUser.role_id === 4 ? 2 : 1;
          this.$store.commit("SET_CHAT_MESSAGES", chatInformation);
        });
    },
    setRatingEmployee() {
      this.$http
        .post(`appointments/${this.appointment.id}/review`, {
          employee_call_quality: this.ratingValue.ratingCall,
          service_quality: this.ratingValue.ratingAppointment,
        })
        .then(() => {
          this.toast.success(this.$t("successesRating"));
        });
    },
    setRatingExpert() {
      this.$http
        .post(`appointments/${this.appointment.id}/review`, {
          expert_call_quality: this.ratingValue.ratingCall,
        })
        .then(() => {
          this.toast.success(this.$t("successesRating"));
        });
    },
  },
  watch: {
    interval(newValue) {
      this.appointmentDurationTimer(newValue);
    },
    chatVisible(value) {
      this.$store.commit("SET_SHOW_CHAT", value);
      if (value) {
        this.scrollBottom();
      }
    },
    ratingValue: {
      deep: true,
      handler() {
        if (!this.rating) return;
        if (this.currentUser.role_id === 4) {
          this.setRatingEmployee();
        }
        if (this.currentUser.role_id === 2) {
          this.setRatingExpert();
        }
      },
    },
    userInformation: {
      deep: true,
      handler(value) {
        this.setLocale(value.language_code);
      },
    },
    bgBlur(value) {
      this.setBlur(value);
    },
  },
  computed: {
    ...mapGetters({
      chats: "CHATS",
      currentUser: "CURRENT_USER_INFORMATION",
      chatInformation: "CHAT_MESSAGES",
      userInformation: "CURRENT_USER_INFORMATION",
    }),
  },
};
</script>

<style scoped lang="sass">
.switch
  position: relative
  border: 1px solid
  margin-right: 5px
  border-radius: 10px
  display: inline-block
  width: 26px
  height: 16px

.switch input
  display: none

.slider
  position: absolute
  cursor: pointer
  top: 0
  left: 0
  right: 0
  bottom: 0
  background-color: transparent
  -webkit-transition: .4s
  transition: .4s

.slider:before
  position: absolute
  content: ""
  height: 10px
  width: 10px
  left: 3px
  bottom: 2px
  background-color: #000000
  -webkit-transition: .4s
  transition: .4s

input:checked + .slider
  background-color: #000000

input:focus + .slider
  box-shadow: 0 0 1px #000000

input:checked + .slider:before
  transform: translateX(8px)
  background-color: #fff

.slider.round
  border-radius: 34px

.slider.round:before
  border-radius: 50%

.tooltip-text
  visibility: hidden
  width: max-content
  background-color: #555
  color: #fff
  text-align: center
  padding: 5px
  border-radius: 6px
  position: absolute
  z-index: 1
  bottom: 125%
  left: 50%
  transform: translateX(-50%)
  opacity: 0
  transition: opacity 0.3s

.tooltip-text::after
  content: ""
  position: absolute
  top: 100%
  left: 50%
  margin-left: -5px
  border-width: 5px
  border-style: solid
  border-color: #555 transparent transparent transparent

.appointmentCallPage__ratingBtn:hover .tooltip-text
  visibility: visible
  opacity: 1
</style>
