<template>
  <div class="chatMessage" :class="{ chatMessage__upcoming: upcoming }">
    <div class="chatMessage__text">
      {{ chatMessage.text }}
    </div>
    <div
      v-if="chatMessage.files.length !== 0"
      class="notificationsCards__attachment"
    >
      <div class="chatMessage__attachmentTitle">
        {{ chatMessage.files.length }}
        {{ chatMessage.files.length === 1 ? "Attachment:" : "Attachments:" }}
      </div>
      <div v-for="file in chatMessage.files" :key="file.id">
        <div class="chatMessage__file">
          <notification-icons />
          <a
            :href="`${defaultPath}/chats/${filePath}/${file.name}`"
            target="_blank"
            >{{ file.name }}</a
          >
        </div>
      </div>
    </div>
    <div class="chatMessage__data">
      <full-data :data="chatMessage.date" />
    </div>
  </div>
</template>

<script>
import NotificationIcons from "@/components/UI/icons/buttonIcons/NotificationIcons.vue";
import FullData from "@/components/components-helpers/FullData";

export default {
  props: {
    chatMessage: Object,
    filePath: String,
    upcoming: {
      type: Boolean,
    },
  },
  name: "ChatMessage",
  data() {
    return {
      defaultPath: process.env.VUE_APP_ORIGIN_UPLOADS_PATH,
    };
  },
  components: { FullData, NotificationIcons },
};
</script>

<style></style>
